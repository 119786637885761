import React, { useState, useEffect } from 'react';

const Home = () => {
  const originalText = "...Ready soon...";
  const charset = "!<>-_\\/[]{}—=+*^?#________";
  const [glitchText, setGlitchText] = useState(originalText);

  useEffect(() => {
    const interval = setInterval(() => {
      setGlitchText(oldText => {
        if (Math.random() < 0.1) { 
          return oldText
            .split('')
            .map(char => (Math.random() < 0.5 ? charset[Math.floor(Math.random() * charset.length)] : char))
            .join('');
        }
        return originalText; 
      });
    }, 200); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="centered-content">
      <h1 className="glitch" data-text={glitchText}>{glitchText}</h1>
      <p className="small-text">play.btfo.ai</p>
      <a href="https://docs.neworder.ai" className="button" target="_blank" rel="noopener noreferrer">Learn More</a>
    </div>
  );
};

export default Home;
